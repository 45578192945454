import { Link } from 'gatsby';
import React from 'react';

import '../styles/desktop_nav.scss';

const DesktopNav = () => (
    <div className="desktopNav">
        <div className="nav-item"><Link to="/about">about</Link></div>
    </div>
)

export default DesktopNav;